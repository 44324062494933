<template>
  <div>
      <div class="search">
        <div class="searchFl">
          <portSelect
              v-model="desc"
              placeTitle="请输入目的港"
              clearable
              @input="dest_port_input"
          />
          <el-date-picker
              style="margin-left:20px"
              v-model="time"
              type="daterange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div>
          <el-button size="small" type="primary" @click="searchChange">查询</el-button>
        </div>
      </div>
      <div class="line"></div>
      <div class="tableBlock">
        <el-table :data="tableData" style="width: 100%" :height="tableHeight" v-tableHeight="{ bottomOffset: 74 }" :tableLoading="loading">
          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="港口信息"
          >
            <div slot-scope="scope" class="protMsg">
              <div class="from">
                <p>{{ scope.row.from_port_en | textFormat }}</p>

                <p>{{ scope.row.from_port | textFormat }}</p>
              </div>
              <div class="jt">>>></div>
              <div class="dest">
                <p>{{ scope.row.dest_port_en | textFormat }}</p>

                <p>{{ scope.row.dest_port | textFormat }}</p>
              </div>
            </div>
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="中转港"
          >
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="船公司"
          >
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="货物类型"
          >
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="船期"
          >
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="箱货信息"
          >
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="海运费(USD)"
          >
          </el-table-column>

          <el-table-column
              prop="date"
              align="center"
              show-overflow-tooltip
              label="有效期"
          >
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="100">
            <template slot-scope="scope">
              <el-button @click="handleDetail(scope.row)" type="danger" size="small">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect";
export default {
  name: "list",
  components: { portSelect },
  data () {
    return {
      desc: "",
      loading: false,
      tableHeight: 100,
      time: [],
      tableData: [{date: 1}]
    }
  },
  mounted() {
  },
  methods:{
    searchChange () {
      console.log(123);
      // console.log(this.$moment(this.time[0]).unix());
    },
    dest_port_input (data) {
      console.log(data)
    },
    handleDetail (row) {
      this.$router.push({
        path: '/console/customer/detail',
        query: {
          id: 1
        }
      })
      console.log(row);
    }
  }
}
</script>

<style scoped lang="less">
.protMsg {
  display: flex;
  justify-content: space-between;
  .from {
    width: 100px;
  }
  .jt {
    width: 50px;
    color: #027fff;
  }
  .desc {
    width: 100px;
  }
}
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc;
  margin-bottom: 20px;
}
</style>